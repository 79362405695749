
import Vue from 'vue';
// @ts-ignore
import { AisRefinementList } from 'vue-instantsearch';

import { EFacetNameRefinementList } from '@/domain/core/algolia/types';
import { RefinementListItem, maxBatchQuantityIndexed } from '@/infrastructure/externalServices/algolia/types';

export default Vue.extend({
  name: 'InstantSearchCheckboxes',
  components: {
    AisRefinementList,
  },
  props: {
    facetName: {
      type: String,
      required: true,
    },
    isSearchable: {
      type: Boolean,
      default: false,
    },
    isCapitalized: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 100,
    },
    sortBy: {
      type: Array,
      default: () => ['name:asc', 'count:desc'],
    },
    isCrawlable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    transformItems(items: RefinementListItem[]): RefinementListItem[] {
      const baseI18nPath = `page.search.facets.${this.facetName}`;

      return items.map((item: any, index: number) => {
        const value = item?.value || '';
        const currentI18nKey = this.shouldDisplayCustomLastItemLabel(items, index, Number.parseInt(value, 10))
          ? 'customLastItemLabel'
          : 'customLabel';
        let [, displayValue] = value.split('||');
        const unescapeValue = value.replace('\\', ''); // Algolia escapes negative values used for IQM
        const valueAsNumber = Number.parseInt(unescapeValue, 10);

        if (this.$te(`${baseI18nPath}.${currentI18nKey}`) && !Number.isNaN(valueAsNumber)) {
          displayValue = this.$tc(`${baseI18nPath}.${currentI18nKey}`, valueAsNumber);
        }

        if (this.facetName === EFacetNameRefinementList.IsHandmade && this.$te(`${baseI18nPath}.values.${value}`)) {
          displayValue = this.$t(`${baseI18nPath}.values.${value}`);
        }

        return {
          ...item,
          label: displayValue ?? value,
        };
      });
    },
    shouldDisplayCustomLastItemLabel(items: RefinementListItem[], index: number, value: number) {
      const isLastItem = index === items?.length - 1;

      if (!isLastItem) {
        return false;
      }

      if (this.facetName === EFacetNameRefinementList.Quantity && value) {
        return value === maxBatchQuantityIndexed;
      }

      return true;
    },
  },
});


import Vue, { PropType } from 'vue';

import IcCheck from '@/assets/svg/ic_check.svg?inline';

export default Vue.extend({
  name: 'InputCheckbox',
  components: {
    IcCheck,
  },
  props: {
    cssSpacingClasses: {
      type: String,
      default: '',
    },
    dataTestId: {
      type: String,
      default: 'input-checkbox',
    },
    error: {
      type: String as PropType<string | null>,
      default: null,
    },
    inputId: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    modelValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('update:value', value);
      },
    },
  },
});

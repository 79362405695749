
import Vue from 'vue';
// @ts-ignore
import { AisClearRefinements, AisCurrentRefinements } from 'vue-instantsearch';

import { EFacetNameRefinementList as EFacetList } from '@/domain/core/algolia/types';
import { CurrentRefinementList } from '@/infrastructure/externalServices/algolia/types';

export default Vue.extend({
  name: 'InstantSearchCategoriesFilter',
  components: {
    AisClearRefinements,
    AisCurrentRefinements,
  },
  props: {
    filters: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      EFacetList,
      clearCurrentCategories: [
        EFacetList.CategoryLevel2,
        EFacetList.CategoryLevel3,
      ],
      activeRefinements: [] as CurrentRefinementList[],
    };
  },
  computed: {
    categoryLevel2Refinements(): CurrentRefinementList[] {
      return this.activeRefinements?.filter((refinement: CurrentRefinementList) => refinement.attribute === EFacetList.CategoryLevel2) || [];
    },
    currentCategoryFilter(): { name?: string; value?: string } {
      const [facetFilterName = '', facetFilterValue = ''] = this.filters
        ?.replace(/"/g, '')
        ?.split(/\s(AND|OR|NOT)\s/s)
        ?.find((filter) => this.isCategoryFilter(filter))
        ?.split(':') || [];

      return {
        name: facetFilterName,
        value: facetFilterValue,
      };
    },
    isCategoryLevel1(): boolean {
      return this.currentCategoryFilter.name === EFacetList.CategoryLevel1;
    },
    isCategoryLevel2(): boolean {
      return this.currentCategoryFilter.name === EFacetList.CategoryLevel2;
    },
    isCategoryLevel2Refined(): boolean {
      return this.categoryLevel2Refinements.length > 0;
    },
    isCategoryLevel3(): boolean {
      return this.currentCategoryFilter.name === EFacetList.CategoryLevel3;
    },
    refinedCategoryLevel2Title(): string {
      const [facetFilter] = this.categoryLevel2Refinements;
      const [categoryRefinement] = facetFilter?.refinements || [];

      return categoryRefinement?.label || '';
    },
    shouldDisplayCategoriesFilter(): boolean {
      const isCategoryPage = this.$route.name === 'category';

      return isCategoryPage && !this.isCategoryLevel3;
    },
  },
  methods: {
    isCategoryFilter(filter: string): boolean {
      const allCategoryLevels = [EFacetList.CategoryLevel1, EFacetList.CategoryLevel2, EFacetList.CategoryLevel3];

      return allCategoryLevels.some((category) => filter.includes(category));
    },
    transformItems(items: CurrentRefinementList[]): CurrentRefinementList[] {
      // Store refinements in local data and return empty so that widget does not render.
      this.activeRefinements = items;

      return [];
    },
  },
});

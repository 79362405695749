var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative",class:[_vm.cssSpacingClasses],attrs:{"data-testid":_vm.dataTestId}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelValue),expression:"modelValue"}],staticClass:"input-hidden",attrs:{"id":_vm.inputId,"disabled":_vm.isDisabled,"name":_vm.inputName,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.modelValue)?_vm._i(_vm.modelValue,null)>-1:(_vm.modelValue)},on:{"change":function($event){var $$a=_vm.modelValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.modelValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.modelValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.modelValue=$$c}}}}),_vm._v(" "),_c('label',{staticClass:"flex items-start",class:{
      'cursor-not-allowed text-gray-darker': _vm.isDisabled,
      'cursor-pointer': !_vm.isDisabled,
    },attrs:{"for":_vm.inputId}},[_c('span',{staticClass:"relative flex-none inline-block size-4 mt-0.5 mr-2 transition border border-black",class:{
        'bg-transparent': !_vm.modelValue,
        'bg-black': _vm.modelValue,
      }},[_c('IcCheck',{staticClass:"absolute text-white block size-5 transition origin-center -left-[3px] -top-0.5",class:{
          'scale-0 opacity-0': !_vm.modelValue,
          'opacity-1': _vm.modelValue,
        },attrs:{"aria-hidden":"true","data-testid":`${_vm.dataTestId}-icon-check`,"focusable":"false"}})],1),_vm._v(" "),_c('span',{staticClass:"text-sm select-none"},[_vm._t("default")],2)]),_vm._v(" "),_c('TransitionCollapse',[(_vm.error)?_c('p',{staticClass:"text-xs text-red",attrs:{"data-testid":`${_vm.dataTestId}-error`}},[_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
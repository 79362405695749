
import Vue from 'vue';

export default Vue.extend({
  name: 'InstantSearchCollapsible',
  props: {
    description: {
      type: String,
      default: '',
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    shouldOpenCollapsible(): boolean {
      return this.isOpen && !this.$device.isMobile;
    },
  },
});
